.inbox-page .row {
  cursor: pointer; }

.inbox-page .content {
  padding: 16px; }

.inbox-page .conversation-indicator-container {
  display: flex;
  align-items: center;
  justify-content: center; }
  .inbox-page .conversation-indicator-container .conversation-indicator {
    background: red;
    border-radius: 100%;
    width: 1rem;
    height: 1rem; }

.inbox-page .conversation-image {
  width: 10rem;
  height: 10rem;
  background-size: cover; }

.inbox-page .conversation-image-placeholder {
  width: 10rem;
  height: 10rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-style: italic;
  outline: 0.1rem solid #8080805c; }

.inbox-page .conversation-last-message {
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column; }
