.artworks-page .content {
  padding: 16px; }
  .artworks-page .content .add-artwork {
    margin: 5px;
    width: auto; }
  .artworks-page .content .search-artwork {
    margin: 5px; }
  .artworks-page .content .items-container {
    height: 100%;
    padding-top: 8px; }

.add-artwork-page .content {
  min-height: calc(100vh - 5.5rem);
  max-width: 100%;
  scroll-behavior: auto;
  overflow-y: scroll;
  padding: 16px; }

.add-artwork-page .footer-buttons .back {
  margin-left: 5px; }

.add-artwork-page .footer-buttons .publish {
  margin-left: 5px; }

.add-artwork-page .footer-buttons .delete {
  margin-left: 5px; }
