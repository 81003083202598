.titled-component-container {
  display: block;
  flex-wrap: wrap;
  align-items: top; }
  .titled-component-container .title {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 1rem; }
    .titled-component-container .title h5 {
      text-transform: uppercase;
      margin-bottom: 0; }
    .titled-component-container .title .description-text {
      color: #9a9a9a;
      font-style: italic; }
  .titled-component-container .component-container {
    flex-grow: 1; }
