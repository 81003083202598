.offers-page .content {
  padding: 16px; }
  .offers-page .content .button-icon-status-change {
    display: flex;
    align-items: center;
    margin-right: 0.75rem; }
    .offers-page .content .button-icon-status-change > .icon-button {
      margin-right: 0.5rem; }
  .offers-page .content .items-container {
    height: 100%;
    padding-top: 8px; }
