.dropzone-container {
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  margin-bottom: 2.3rem;
  border-width: 2;
  border-radius: 2;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.25s ease-in-out; }
  .dropzone-container.active {
    border-color: #2196f3; }
  .dropzone-container.accept {
    border-color: #00e676; }
  .dropzone-container.reject {
    border-color: #ff1744; }

.dropzone-inner {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .dropzone-inner .file-entry {
    display: flex;
    align-items: center; }
    .dropzone-inner .file-entry > i {
      margin: 0 0.5rem; }
      .dropzone-inner .file-entry > i:hover {
        cursor: pointer !important;
        color: #000; }
  .dropzone-inner > span {
    display: flex;
    align-items: center; }
