.user-container .image {
  margin: 10px 0px;
  width: 140px;
  height: 140px; }

.user-container .name {
  max-width: 120px;
  font-weight: bold;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
  text-transform: uppercase; }

.view-button {
  font-weight: bold;
  border: 2px  solid;
  min-width: 140px; }

.data-container {
  min-width: 140px;
  display: flex;
  justify-content: center; }

.icon-delete {
  font-size: 16px; }
