.dashboard-page .content {
  padding: 10px; }
  .dashboard-page .content .dashboard_section {
    display: flex;
    align-items: center;
    justify-content: space-between; }

.dashboard-page .container {
  flex-direction: row;
  padding: 25px; }

.dashboard-page table {
  padding: 0px 18px;
  width: 100%;
  color: #8c8c8c; }

.dashboard-page .count {
  font-size: 45px;
  line-height: 45px;
  font-family: monospace;
  font-weight: lighter; }

@media screen and (max-width: 800px) {
  .dashboard-page .content .dashboard_section {
    flex-direction: column;
    padding: 0 20px; } }
