.settings-page .content {
  scroll-behavior: auto;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  padding: 16px; }
  .settings-page .content .input-container {
    width: 80%; }
    .settings-page .content .input-container .input-settings-fields {
      margin-top: 24px;
      margin-bottom: 10px;
      width: 100%; }
  .settings-page .content .item {
    display: flex; }
    .settings-page .content .item .info-key {
      width: 30%; }
    .settings-page .content .item .info-value {
      width: 70%; }
  .settings-page .content .input input {
    width: 80%; }
  .settings-page .content .footer-buttons {
    margin-top: 50px; }
