.custom-modal .modal-body {
  padding-top: 0; }

.custom-modal .modal-dialog .modal-content {
  border-radius: 0; }

.custom-modal .close {
  background: #d6d6d6;
  margin-right: 20px;
  padding: 9px 9px 12px 9px;
  outline: 0 !important;
  line-height: 1rem; }

.custom-modal .modal-header {
  border: 0;
  justify-content: flex-end; }

.custom-modal .title {
  width: 100%;
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 15px; }

.custom-modal .input_field {
  width: 100%;
  display: inline-block;
  padding: 2px 10px;
  margin-bottom: 15px;
  outline: 0 !important;
  font-size: 14px; }

.custom-modal .button_field {
  width: 100%;
  display: inline-block;
  background: black;
  border: 0;
  padding: 5px;
  color: white;
  outline: 0 !important;
  cursor: pointer;
  margin-bottom: 20px; }

.custom-modal .description {
  font-size: 13px;
  width: 100%;
  display: inline-block;
  line-height: 19px; }

.custom-modal .artist-add-row {
  width: 100%;
  display: flex;
  border: 1px solid #d6d6d6;
  padding-left: 10px;
  align-items: center;
  justify-content: space-between; }

.custom-modal .artist-add-row .arstist_name {
  font-size: 12px; }

.custom-modal .artist-add-row .add_artist_btn {
  font-size: 13px;
  padding: 3px 10px;
  outline: 0 !important;
  cursor: pointer;
  background: black;
  border: 0;
  color: white; }
