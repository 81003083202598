.auctions-page .content {
  padding: 16px; }
  .auctions-page .content .add-auction {
    margin: 5px;
    width: auto; }
  .auctions-page .content .search-auction {
    margin: 5px; }
  .auctions-page .content .items-container {
    overflow-y: scroll;
    overflow: auto;
    height: 100%;
    padding-top: 8px; }
