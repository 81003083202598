html {
  font-size: 10px !important; }
  html #__next {
    height: 100%; }
  html p,
  html span {
    font-size: 1em; }
  html h1 {
    font-size: 2em; }
  html h2 {
    font-size: 1.5em; }
  html h3 {
    font-size: 1.17em; }
  html h4 {
    font-size: 1em; }
  html h5 {
    font-size: 0.83em; }
  html h6 {
    font-size: 0.67em; }

.artscoops {
  height: 100vh;
  margin: 0px; }

.rdtPicker {
  margin-bottom: 2.3rem; }

.rdtCounters {
  display: flex;
  align-items: center; }
  .rdtCounters > div {
    margin-right: 0.5rem; }

.rdtDays thead > tr {
  text-align: center; }
  .rdtDays thead > tr > th:hover {
    cursor: pointer;
    background: lightgray; }

.rdtDays tbody {
  outline: 0.01rem solid; }
  .rdtDays tbody > tr > td {
    border-bottom: 0.01rem solid;
    padding: 0.6rem;
    text-align: center;
    border-right: 0.01rem solid; }
    .rdtDays tbody > tr > td:hover {
      cursor: pointer;
      background: lightgray; }
    .rdtDays tbody > tr > td.rdtOld {
      color: darkgray; }
      .rdtDays tbody > tr > td.rdtOld:hover {
        color: inherit; }

.rdtTimeToggle {
  outline: 0.01rem solid;
  text-align: center; }
  .rdtTimeToggle:hover {
    cursor: pointer;
    background: lightgray; }

.main-separatar {
  height: 1px;
  width: 100%;
  background: lightgrey;
  margin-top: 5px;
  margin-bottom: 5px; }

.subtitle {
  font-size: 14px;
  text-shadow: 1px 1px #0001;
  margin: 5px; }

.text-app {
  min-width: 140px;
  margin-top: 28px;
  margin-bottom: 10px; }

.button {
  min-width: 140px; }

.dropdown-semantic {
  width: 100%;
  margin-bottom: 23px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid lightgrey;
  text-shadow: 1px 1px #0001; }

.popup, .dropdown-semantic .item {
  font-size: 1.4rem !important; }

.pointer-events-none {
  pointer-events: none; }

@media screen and (max-width: 1025px) {
  .text-app {
    min-width: initial !important; } }
