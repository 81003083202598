.thread-page .content {
  padding: 16px; }
  .thread-page .content .row {
    min-height: 5rem; }

.thread-page .thread-header {
  display: flex;
  align-items: center; }
  .thread-page .thread-header > img {
    margin-right: 1rem; }

.thread-page .conversation-image-placeholder {
  width: 10rem;
  height: 10rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-style: italic;
  outline: 0.1rem solid #8080805c;
  font-weight: normal;
  margin-right: 1rem; }

.thread-page .attachment-list {
  list-style: none;
  margin: 0;
  padding: 0; }

.thread-page .attachment-file {
  margin-bottom: 0.5rem; }

.thread-page .attachment-thumbnail {
  width: 3rem;
  border-radius: 0.5rem;
  margin: 0 0.5rem; }

.thread-page .thread_input {
  width: 100%; }
  .thread-page .thread_input textarea {
    max-width: none; }
  .thread-page .thread_input button {
    max-width: 20rem; }
