.shows-page .content {
  padding: 16px; }
  .shows-page .content .add-show {
    margin: 5px;
    width: auto; }
  .shows-page .content .search-show {
    margin: 5px; }
  .shows-page .content .items-container {
    overflow-y: scroll;
    overflow: auto;
    height: 100%;
    padding-top: 8px; }

.add-show-page .content {
  max-width: 100%;
  scroll-behavior: auto;
  overflow-y: scroll;
  padding: 16px; }

.add-show-page .footer-buttons .back {
  margin-left: 5px; }

.add-show-page .footer-buttons .publish {
  margin-left: 5px; }

.add-show-page .footer-buttons .delete {
  margin-left: 5px; }
