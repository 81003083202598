.add-auction-page .content {
  max-width: 100%;
  scroll-behavior: auto;
  overflow-y: scroll;
  padding: 16px; }

.add-auction-page .footer-buttons .back {
  margin-left: 5px; }

.add-auction-page .footer-buttons .publish {
  margin-left: 5px; }

.add-auction-page .footer-buttons .delete {
  margin-left: 5px; }
