.artwork-item .center-items {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center; }

.artwork-item .image {
  margin: 10px 0px;
  max-width: 100%; }

.artwork-item .artist-name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px; }

.artwork-item .artwork-name {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px; }

.artwork-item .offer-price {
  font-size: 20px;
  font-weight: bold;
  margin-top: 1rem; }

.artwork-item .view-button {
  font-weight: bold;
  background: transparent;
  color: black;
  border: 2px  solid black; }

.artwork-item .delete {
  transition: all .8s; }
  .artwork-item .delete:hover {
    transform: rotate(360deg) scale(1.3); }

.artwork-item .button-icon {
  margin-right: 0.7rem; }

.artwork-item .icon-button {
  font-size: 16px; }
