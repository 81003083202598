.artists-page .content {
  padding: 16px; }
  .artists-page .content .add-artist {
    margin: 5px;
    width: auto; }
  .artists-page .content .search-artist {
    margin: 5px; }
  .artists-page .content .items-container {
    padding-top: 8px;
    overflow-y: scroll;
    overflow: auto;
    height: 100%; }

.add-artist-page .content {
  padding: 16px;
  max-width: 100%;
  scroll-behavior: auto;
  overflow-y: scroll; }
  .add-artist-page .content .note {
    margin: 10px 0px; }

.add-artist-page .footer-buttons .back {
  margin-right: 5px; }

.add-artist-page .footer-buttons .add {
  margin-left: 5px; }

.add-artist-page .rdt > input {
  font-size: 1.4rem; }

.add-artist-page .rdtSwitch {
  cursor: pointer; }

.add-artist-page .rdtMonths > table, .add-artist-page .rdtYears > table {
  width: 100%; }

.add-artist-page .rdtDay, .add-artist-page .rdtMonth, .add-artist-page .rdtYear, .add-artist-page .rdtNext, .add-artist-page .rdtPrev {
  cursor: pointer; }
  .add-artist-page .rdtDay > table, .add-artist-page .rdtMonth > table, .add-artist-page .rdtYear > table, .add-artist-page .rdtNext > table, .add-artist-page .rdtPrev > table {
    width: 100%; }
  .add-artist-page .rdtDay:hover, .add-artist-page .rdtMonth:hover, .add-artist-page .rdtYear:hover, .add-artist-page .rdtNext:hover, .add-artist-page .rdtPrev:hover {
    background: lightgray; }
