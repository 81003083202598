.image-name-container {
  cursor: pointer; }
  .image-name-container .image {
    margin: 10px 0px;
    width: 140px;
    height: 140px; }
  .image-name-container .name {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    margin-left: 10px; }
  .image-name-container .date {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    margin-left: 10px; }
  .image-name-container .view-button {
    font-weight: bold;
    background: transparent;
    color: black;
    border: 2px  solid black; }
