.authentication-page {
  height: 100%;
  display: flex; }
  .authentication-page .content {
    width: fit-content;
    margin: auto; }
    .authentication-page .content .title {
      font-weight: bold;
      font-size: 18px;
      margin: 25px; }
    .authentication-page .content .forget-password {
      font-size: 12px;
      color: #777777;
      font-weight: normal;
      cursor: pointer;
      text-shadow: 1px 1px #0001; }
    .authentication-page .content .error {
      margin: auto;
      display: flex;
      justify-content: center;
      color: red; }
