.button-div {
  width: 250px; }

.instructions {
  padding-bottom: 5px; }
  .instructions p {
    color: #9a9a9a;
    font-style: italic;
    margin-bottom: 0; }

.select-file-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 250px;
  border: 2px solid lightgrey;
  margin-top: 8px;
  margin-bottom: 8px;
  justify-content: space-between; }
  .select-file-container input {
    display: none; }
  .select-file-container .choose-file {
    position: absolute;
    bottom: 0px;
    top: 0px;
    right: 0px;
    left: 0px;
    z-index: 8;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    color: black;
    padding: 10px 32px; }
  .select-file-container .controls-file {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    right: 0px;
    left: 0px;
    top: 0px;
    height: 32px;
    z-index: 10;
    cursor: pointer;
    padding: 5px;
    color: #fffa;
    background: #0003; }
    .select-file-container .controls-file:hover {
      background: black;
      color: white; }
  .select-file-container .name {
    color: white;
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    height: 28px;
    overflow-y: scroll;
    mix-height: 28px;
    z-index: 10;
    color: white;
    background: #0003; }
    .select-file-container .name:hover {
      background: black; }
  .select-file-container .img-container {
    width: 246px;
    height: 246px;
    background-color: black;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; }
